import { BackButton } from "../UI/Buttons";

export const Datenschutz = () => {
    return (
        <>
            <BackButton path={-1} />

            <div className="datenschutz center">
                <div className="impressum-content ">

                    <h2>Datenschutzerklärung</h2>

                    <div>
                        <h2>1. Einleitung</h2>
                        <p>   Mit dieser Datenschutzerklärung möchten wir Sie über Art und Umfang der Verarbeitung Ihrer personenbezogenen Daten (nachfolgend auch „Daten“ genannt) informieren. Sie erfahren, welche Arten von personenbezogenen Daten wir zu welchem Zweck im Rahmen der Erbringung unserer Leistungen als auch bei Ihrem Besuch auf unserer Webseite verarbeiten. </p>

                        <h2>2. Verantwortlicher   </h2>
                        <p>Verantwortlicher im Sinne der DS-GVO ist die: <br />

                            Joanna Cwalina<br />
                            Kestenbergerweg 6<br />
                            67105 Schifferstadt<br />
                            Telefon: +49 15772195452<br />
                            E-Mail: joannacwalinaa@gmail.com</p>

                        <h2>3. Begriffsbestimmungen   </h2>
                        <p>Die Datenschutzerklärung beruht auf den Begrifflichkeiten, die durch den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für unsere Kunden und Geschäftspartner einfach lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die verwendeten Begrifflichkeiten erläutern.<br /><br />
                            <b>Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:</b>
                        </p>

                        <h3> 1. Personenbezogene Daten </h3>
                        <p> Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind, identifiziert werden kann.
                            <h3> 2. Betroffene Person </h3>
                            Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten von dem für die Verarbeitung Verantwortlichen (unser Unternehmen) verarbeitet werden.
                            <h3>  3. Verarbeitung </h3>
                            Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
                            <h3> 4. Einschränkung der Verarbeitung </h3>
                            Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre künftige Verarbeitung einzuschränken.
                            <h3> 5. Profiling </h3>
                            Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit, persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder vorherzusagen.
                            <h3> 6. Pseudonymisierung </h3>
                            Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die personenbezogenen Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.
                            <h3> 7. Auftragsverarbeiter </h3>
                            Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
                            <h3> 8. Empfänger </h3>
                            Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.
                            <h3> 9. Dritter </h3>
                            Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der betroffenen Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.
                            <h3>10. Einwilligung  </h3>
                            Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in informierter Weise und unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass Sie mit der Verarbeitung der Sie betreffenden personenbezogenen Daten einverstanden ist.</p>


                        <h2>  4. Rechtsgrundlage der Verarbeitung </h2>
                        <p> Art. 6 Abs. 1 lit. a) DS-GVO (i.V.m. § 25 Abs. 1 TTDSG) dient unserem Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten Verarbeitungszweck einholen.<br /> <br />
                            Ist die Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei Sie sind, erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine Lieferung von Waren oder die Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 Abs. 1 lit. b) DS-GVO. Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zu unseren Produkten oder Leistungen.<br /> <br />
                            Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch welche eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert die Verarbeitung auf Art. 6 Abs. 1 lit. c) DS-GVO.<br /> <br />
                            In seltenen Fällen könnte die Verarbeitung von personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen. Dies wäre beispielsweise der Fall, wenn ein Besucher in unserem Betrieb verletzt werden würde und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder sonstige lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 Abs. 1 lit. d) DS-GVO beruhen.<br /> <br />
                            Letztlich könnten Verarbeitungsvorgänge auf Art. 6 Abs. 1 lit. f) DS-GVO beruhen. Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen.
                        </p>

                        <h2>  5. Übermittlung von Daten an Dritte </h2>
                        <p>   Eine Übermittlung Ihrer personenbezogenen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet nicht statt.<br /> <br />
                            <b>Wir geben Ihre personenbezogenen Daten nur an Dritte weiter, wenn:</b> <br /> <br />
                            1. Sie uns Ihre nach Art. 6 Abs. 1 lit. a) DS-GVO ausdrückliche Einwilligung dazu erteilt haben,<br /> <br />
                            2. die Weitergabe nach Art. 6 Abs. 1 lit. f) DS-GVO zur Wahrung unserer berechtigten Interessen zulässig ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben,<br /> <br />
                            3. für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 lit. c) DS-GVO eine gesetzliche Verpflichtung besteht, sowie<br /> <br />
                            4. dies gesetzlich zulässig und nach Art. 6 Abs. 1 lit. b) DS-GVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.<br /> <br />

                            Im Rahmen der in dieser Datenschutzerklärung beschriebenen Verarbeitungsvorgänge können personenbezogene Daten in die USA übermittelt werden. Unternehmen in den USA verfügen nur dann über ein angemessenes Datenschutzniveau, sofern sie sich unter dem EU-US Data Privacy Framework zertifiziert haben und somit der Angemessenheitsbeschluss der EU-Kommission gem. Art. 45 DS-GVO greift. Wir haben dies bei den betroffenen Dienstleistern in der Datenschutzerklärung explizit genannt. <br /> <br />
                            Um Ihre Daten in allen anderen Fällen zu schützen, haben wir Vereinbarungen zur Auftragsverarbeitung auf Grundlage der Standardvertragsklauseln der Europäischen Kommission abgeschlossen. Sofern die Standardvertragsklauseln nicht ausreichen, um ein adäquates Sicherheitsniveau herzustellen kann Ihre Einwilligung gemäß Art. 49 Abs. 1 lit. a) DS-GVO als Rechtsgrundlage für die Übermittlung in Drittländer dienen. Dies gilt mitunter nicht bei einer Datenübermittlung in Drittländer, für welche die Europäische Kommission einen Angemessenheitsbeschluss gemäß Art. 45 DS-GVO ausgestellt hat.
                        </p>


                        <h2>  6. SSL/TLS-Verschlüsselung </h2>
                        <p>  Diese Seite nutzt zur Gewährleistung der Sicherheit der Datenverarbeitung und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen, Login-Daten oder Kontaktanfragen, die Sie an uns als Betreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass in der Adresszeile des Browsers statt einem "http://" ein "https://" steht und an dem Schloss-Symbol in Ihrer Browserzeile. Wir setzen diese Technologie ein, um Ihre übermittelten Daten zu schützen.</p>

                        <h2>  7. Datenerfassung beim Besuch der Internetseite </h2>
                        <p> Bei der lediglich informatorischen Nutzung unserer Website, wenn Sie sich nicht registrieren oder uns anderweitig Informationen übermitteln oder keine Einwilligung in einwilligungspflichtige Verarbeitungen geben, erheben wir nur solche Daten, die für die Bereitstellung des Dienstes technisch unbedingt notwendig sind. Dies sind regelmäßig Daten, die Ihr Browser an unseren Server übermittelt ("in sog. Server-Logfiles"). Unsere Internetseite erfasst mit jedem Aufruf einer Seite durch Sie oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Diese allgemeinen Daten und Informationen werden in den Logfiles des Servers gespeichert. Erfasst werden können: <br /> <br />

                            1. verwendeten Browsertypen und Versionen,<br />
                            2. das vom zugreifenden System verwendete Betriebssystem,<br />
                            3. die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite gelangt (sogenannte Referrer),<br />
                            4. die Unterseiten, welche über ein zugreifendes System auf unserer Internetseite angesteuert werden,<br />
                            5. das Datum und die Uhrzeit eines Zugriffs auf die Internetseite,<br />
                            6. eine Internet-Protokoll-Adresse (IP-Adresse) und,<br />
                            7. der Internet-Service-Provider des zugreifenden Systems.<br />
                            <br />
                            Bei der Nutzung dieser allgemeinen Daten und Informationen ziehen wir keine Rückschlüsse auf Ihre Person. Diese Informationen werden vielmehr benötigt, um<br /><br />
                            1. die Inhalte unserer Internetseite korrekt auszuliefern,<br />
                            2. die Inhalte unserer Internetseite sowie die Werbung für diese zu optimieren,<br />
                            3. die dauerhafte Funktionsfähigkeit unserer IT-Systeme und der Technik unserer Internetseite zu gewährleisten sowie<br />
                            4. um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen bereitzustellen.<br />
                            <br />
                            Diese erhobenen Daten und Informationen werden von unserem Dienstleister Netlify, Inc., 610 22nd Street, Suite 315, San Francisco, CA 94107, USA verarbeitet. Dieser speichert dabei einige Informationen über die Verbindung in einer sogenannten Logdatei, um letztlich ein optimales Schutzniveau für die von uns verarbeiteten personenbezogenen Daten sicherzustellen. Wir haben keinen Zugriff auf diese Logdateien. Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f) DS-GVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung.<br /><br />
                            Mehr Informationen dazu, wie Netlify Ihre Daten verarbeitet, können Sie der Datenschutzerklärung von Netlify entnehmen. Das Unternehmen Netlify, Inc. ist als US-Unternehmen unter dem EU-US Data Privacy Framework zertifiziert. Es liegt hiermit ein Angemessenheitsbeschluss gem. Art. 45 DS-GVO vor, sodass eine Übermittlung personenbezogener Daten auch ohne weitere Garantien oder zusätzliche Maßnahmen erfolgen darf.</p>

                        <h2>8. Hosting durch Strato                        </h2>
                        <p> Wir hosten unsere Website bei der Strato AG, Otto-Ostrowski-Straße 7, 10249 Berlin (im Weiteren Strato genannt). Wenn Sie unsere Website besuchen, werden Ihre personenbezogenen Daten (z.B. IP-Adressen in Logfiles) auf den Servern von Strato verarbeitet. Die Verwendung von Strato erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f) DS-GVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung und Bereitstellung sowie Absicherung unserer Website.<br /><br />
                            Wir haben einen Vertrag zur Auftragsverarbeitung (AVV) gem. Art. 28 DS-GVO mit Strato geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass Strato die personenbezogenen Daten unserer Webseitenbesucher nur nach unseren Weisungen und unter Einhaltung der DS-GVO verarbeitet. <br /><br />
                            Nähere Informationen zu Datenschutzbestimmungen von Strato finden Sie unter: https://www.strato.de/datenschutz/
                        </p>


                        <h2>  9. Cookies </h2>

                        <p>Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt. Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-Party-Cookies). Third-Party-Cookies ermöglichen die Einbindung bestimmter Dienstleistungen von Drittunternehmen innerhalb von Webseiten (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen). Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Webseitenfunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies können zur Auswertung des Nutzerverhaltens oder zu Werbezwecken verwendet werden. <br /> <br />
                            Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung der Website (z. B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f) DS-GVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a) DS-GVO und § 25 Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar. <br /> <br />
                            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.

                            Die Cookie-Einstellungen können unter den folgenden Links für die jeweiligen Browser verwaltet werden: <br /> <br />
                            Google Chrome: https://support.google.com/chrome/answer/95647?hl=de&hlrm=en  <br />
                            Mozilla Firefox: https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen  <br />
                            Internet Explorer: http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies  <br />
                            Safari: https://support.apple.com/de-de/guide/safari/sfri11471/mac  <br />
                            Opera: https://help.opera.com/en/latest/
                        </p>


                        <h2> 10. Cookie Law (Consent Management Tool)                        </h2>

                        <p> Wir haben auf unserer Webseite CookieLaw integriert. CookieLaw ist eine Zustimmungslösung der OneTrust Technology Ltd, 82 St John Street, London, EC1M 4JN, United Kingdom, mit der die Einwilligung zur Speicherung von Cookies erhalten und dokumentiert werden kann. CookieLaw nutzt Cookies oder andere Web-Technologien, um Nutzer wiederzuerkennen und die erteilte oder widerrufene Einwilligung zu speichern.  <br /> <br />
                            Die Nutzung des Dienstes erfolgt auf Grundlage die gesetzlich vorgeschriebene Zustimmung zur Verwendung von Cookies zu erhalten gemäß Art. 6 Abs. 1 lit. c) DS-GVO.<br /> <br />
                            Cookie Law erhebt mithilfe von Cookies Daten, die von Endnutzern generiert werden, die unsere Website nutzen. Wenn ein Endnutzer eine Einwilligung abgibt, werden automatisch folgende Daten protokolliert:<br /> <br />
                            <ul>
                                <li>Browserinformationen,</li>
                                <li>Datum und Uhrzeit des Zugriffs,</li>
                                <li>Geräteinformationen,</li>
                                <li>Die URL, von der besuchten Seite,</li>
                                <li>Banner Sprache,</li>
                                <li>Consent ID,</li>
                                <li>Der Einwilligungsstatus des Endnutzers, der als Nachweis der Zustimmung dient.</li>
                            </ul>

                            Die Einwilligungsdaten (Einwilligung und Widerruf der Einwilligung) werden drei Jahre lang gespeichert. Die Aufbewahrungsdauer entspricht der regelmäßigen Verjährungsfrist gemäß § 195 BGB. Die Daten werden dann sofort gelöscht. <br /> <br />
                            Die Funktionsfähigkeit der Webseite ist ohne die beschriebene Verarbeitung nicht gewährleistet. Es besteht seitens des Nutzers keine Widerspruchsmöglichkeit, solange die rechtliche Verpflichtung besteht eine Einwilligung des Nutzers in bestimmte Datenverarbeitungsvorgänge einzuholen (Art. 7 Abs. 1, 6 Abs. 1 S. 1 lit. c) DS-GVO). <br /> <br />
                            Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar, sondern wird von OneTrust Technology Ltd bestimmt. Weitere Hinweise finden Sie in der Datenschutzerklärung für CookieLaw: https://www.onetrust.de/datenschutzerklaerung/.
                        </p>


                        <h2> 11. Kontaktaufnahme / Kontaktformular </h2>

                        <p> Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle der Nutzung eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschließlich zum Zwecke der Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage für die Verarbeitung der Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f) DS-GVO.
                            <br /> <br /> Zielt Ihre Kontaktaufnahme auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b) DS-GVO. Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht, dies ist der Fall, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.                        </p>

                        <h2> 12. Calendly (Terminbuchung)  </h2>
                        <p> Auf unserer Webseite haben Sie die Möglichkeit, Termine mit uns zu vereinbaren. Für die Terminbuchung nutzen wir das Tool „Calendly“. Anbieter ist die Calendly LLC, 271 17th St NW, 10th Floor, Atlanta, Georgia 30363, USA (nachfolgend „Calendly“). <br /> <br />
                            Zum Zweck der Terminbuchung geben Sie die abgefragten Daten und den Wunschtermin in die dafür vorgesehene Maske ein. Die eingegebenen Daten werden für die Planung, Durchführung und ggf. für die Nachbereitung des Termins verwendet. Die Termindaten werden für uns auf den Servern von Calendly gespeichert, dessen Datenschutzerklärung Sie hier einsehen können: https://calendly.com/de/pages/privacy.  <br /> <br />
                            Die von Ihnen eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt. Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f) DS-GVO. Der Websitebetreiber hat ein berechtigtes Interesse an einer möglichst unkomplizierten Terminvereinbarung mit Interessenten und Kunden. Sofern eine Einwilligung abgefragt wurde, ist Art. 6 Abs. 1 lit. a) DS-GVO die Rechtsgrundlage für die Datenverarbeitung; die Einwilligung ist jederzeit widerrufbar. <br /> <br />
                            Das Unternehmen Calendly LLC ist als US-Unternehmen unter dem EU-US Data Privacy Framework zertifiziert. Es liegt hiermit ein Angemessenheitsbeschluss gem. Art. 45 DS-GVO vor, sodass eine Übermittlung personenbezogener Daten auch ohne weitere Garantien oder zusätzliche Maßnahmen erfolgen darf. Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: https://calendly.com/pages/dpa.
                        </p>

                        <h2> 13. Google reCAPTCHA </h2>
                        <p> Wir nutzen Google reCAPTCHA (nachfolgend reCAPTCHA) auf unserer Webseite. Es handelt sich hierbei um einen Dienst des Unternehmens Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Google Ireland Limited ist Teil der Google Firmengruppe mit Hauptsitz in 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. <br /> <br />
                            Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf unseren Webseiten (z.B. in einem Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der Websitebesucher die Webseite betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z.B. IP-Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google weitergeleitet. <br /> <br />
                            Das Mutterunternehmen Google LLC ist als US-Unternehmen unter dem EU-US Data Privacy Framework zertifiziert. Es liegt hiermit ein Angemessenheitsbeschluss gem. Art. 45 DS-GVO vor, sodass eine Übermittlung personenbezogener Daten auch ohne weitere Garantien oder zusätzliche Maßnahmen erfolgen darf. <br /> <br />
                            Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung einer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a DS-GVO. Weitere Informationen zu Google reCAPTCHA sowie die Datenschutzerklärung von Google entnehmen Sie folgenden Links: https://www.google.com/intl/de/policies/privacy/ und https://www.google.com/recaptcha/intro/android.html.
                        </p>

                        <h2> 14. Newsletterversand an Bestandskunden </h2>
                        <p> Wenn Sie uns Ihre E-Mailadresse beim Kauf von Waren bzw. Dienstleistungen zur Verfügung gestellt haben, behalten wir uns vor, Ihnen regelmäßig Angebote zu ähnlichen Waren bzw. Dienstleistungen, wie den bereits gekauften, aus unserem Sortiment per E-Mail zuzusenden. Hierfür müssen wir gemäß § 7 Abs. 3 UWG keine gesonderte Einwilligung von Ihnen einholen. Die Datenverarbeitung erfolgt insoweit allein auf Basis unseres berechtigten Interesses an personalisierter Direktwerbung gemäß Art. 6 Abs. 1 lit. f) DS-GVO. Haben Sie der Nutzung Ihrer E-Mailadresse zu diesem Zweck anfänglich widersprochen, findet ein Mailversand unsererseits nicht statt. Sie sind berechtigt, der Nutzung Ihrer E-Mailadresse zu dem vorbezeichneten Werbezweck jederzeit mit Wirkung für die Zukunft durch eine Mitteilung an den zu Beginn genannten Verantwortlichen zu widersprechen. Hierfür fallen für Sie lediglich Übermittlungskosten nach den Basistarifen an. Nach Eingang Ihres Widerspruchs wird die Nutzung Ihrer E-Mailadresse zu Werbezwecken unverzüglich eingestellt.
                        </p>

                        <h2> 15. Unsere Aktivitäten in sozialen Netzwerken </h2>
                        <p> Damit wir mit Ihnen auch in sozialen Netzwerken kommunizieren und Sie über unsere Leistungen informieren können, sind wir dort mit eigenen Seiten vertreten. Wenn Sie eine unserer Social-Media-Seiten besuchen, sind wir hinsichtlich der dadurch ausgelösten Verarbeitungsvorgänge, im Sinne des Art. 26 DS-GVO, mit dem Anbieter der jeweiligen Social-Media-Plattform gemeinsam für die Verarbeitung verantwortlich. <br /> <br />
                            Wir sind dabei nicht der originäre Anbieter dieser Seiten, sondern nutzen diese lediglich im Rahmen der uns durch die jeweiligen Anbieter gebotenen Möglichkeiten. <br /> <br />
                            Daher weisen wir vorsorglich darauf hin, dass Ihre Daten möglicherweise auch außerhalb der Europäischen Union bzw. des Europäischen Wirtschaftsraumes verarbeitet werden. Eine Nutzung kann daher mit Datenschutzrisiken für Sie verbunden sein, da die Wahrung Ihrer Rechte z.B. auf Auskunft, Löschung, Widerspruch, etc. erschwert sein könnte und die Verarbeitung in den sozialen Netzwerken häufig direkt zu Werbezwecken oder zur Analyse des Nutzerverhaltens durch die Anbieter erfolgt, ohne dass dies durch uns beeinflusst werden kann. Werden durch den Anbieter Nutzungsprofile angelegt, werden dabei häufig Cookies eingesetzt bzw. das Nutzungsverhalten dem von Ihnen erstellten eigenen Mitgliedprofil der sozialen Netzwerke zugeordnet. <br /> <br />
                            Die beschriebenen Verarbeitungsvorgänge personenbezogener Daten erfolgen gemäß Art. 6 Abs. 1 lit. f) DS-GVO auf Basis unseres berechtigten Interesses und des berechtigten Interesses des jeweiligen Anbieters, um mit Ihnen in einer zeitgemäßen Art und Weise kommunizieren bzw. Sie über unsere Leistungen informieren zu können. Müssen Sie bei den jeweiligen Anbietern eine Einwilligung zur Datenverarbeitung als Nutzer abgeben, bezieht sich die Rechtsgrundlage auf Art. 6 Abs. 1 lit. a) DS-GVO i.V.m. Art. 7 DS-GVO. <br /> <br />
                            Da wir keine Zugriffe auf die Datenbestände der Anbieter haben, weisen wir darauf hin, dass Sie Ihre Rechte (z.B. auf Auskunft, Berichtigung, Löschung, etc.) am besten direkt bei dem jeweiligen Anbieter gelten machen. Weiterführende Informationen zur Verarbeitung Ihrer Daten in den sozialen Netzwerken haben wir nachfolgend bei dem jeweiligen von uns eingesetzten Anbieter sozialer Netzwerke aufgeführt: <br /> <br />
                            <h3>1. Facebook</h3>
                            (Mit-) Verantwortlicher für die Datenverarbeitung in Europa:<h2></h2>
                            Meta Platforms Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland<h2></h2>
                            Datenschutzerklärung (Datenrichtlinie): https://www.facebook.com/about/privacy<br /><br />

                            <h3>2. Instagram</h3>
                            (Mit-) Verantwortlicher für die Datenverarbeitung in Deutschland:<h2></h2>
                            Meta Platforms Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland<h2></h2>
                            Datenschutzerklärung (Datenrichtlinie): https://instagram.com/legal/privacy/

                        </p>

                        <h2>16. Font Awesome  </h2>
                        <p> Unsere Webseite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Fonticons Inc, 307 S Main St Ste 202 Bentonville, AR, USA bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in Ihren Browser-Cache, um Texte und Schriftarten korrekt anzuzeigen. <br /> <br />
                            Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Fonticons, Inc. aufnehmen. Hierdurch erlangt Fonticons, Inc. Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Font Awesome erfolgt ausschließlich bei Erteilung einer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO. Wenn Ihr Browser Font Awesome nicht unterstützt, wird eine Standardschrift von Ihrem Endgerät genutzt. <br /> <br />
                            Nähere Informationen zu den Datenschutzbestimmungen von Fonticons Inc. finden Sie unter: https://fontawesome.com/privacy.    </p>



                        <h2>  17. Google WebFonts </h2>
                        <p> Unsere Webseite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts. Die Google WebFonts werden bereitgestellt von der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland. Google Ireland Limited ist Teil der Google Firmengruppe mit Hauptsitz in 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.<br /> <br />

                            Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung einer ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO. Das Mutterunternehmen Google LLC ist als US-Unternehmen unter dem EU-US Data Privacy Framework zertifiziert. Es liegt hiermit ein Angemessenheitsbeschluss gem. Art. 45 DS-GVO vor, sodass eine Übermittlung personenbezogener Daten auch ohne weitere Garantien oder zusätzliche Maßnahmen erfolgen darf.
                            <br /> <br />
                            Weiterführende Informationen zu den Google WebFonts sowie die Datenschutzerklärung von Google können Sie einsehen unter: https://developers.google.com/fonts/faq; https://www.google.com/policies/privacy/. </p>



                        <h2>18. Airbrake  </h2>
                        <p> Wir setzen auf unserer Webseite das Analyse-Tool der Airbrake Technologies Inc., 98 San Jacinto Blvd, Suite 1300, Austin, TX 78701, USA ein (nachfolgend „Airbrake“). Dieses Tool ermöglicht uns eine reibungslose Fehlerüberwachung und gewährt uns Leistungseinblicke in die Leistungsfähigkeit und Performance unserer Website. <br /> <br />
                            Rechtsgrundlage für die in diesem Zusammenhang stattfindende Datenverarbeitung ist unser berechtigtes Interesse gemäß Art.6 Abs.1 Satz 1 lit. f) DS-GVO, die Leistungs- und Funktionsfähigkeit unserer Website mithilfe einer Fehleranalyse sicherzustellen. <br /> <br />
                            Die Datenschutzerklärung von Airbrake finden Sie unter folgendem Link: https://airbrake.io/privacy.  <br /> <br />
                            Wir haben ein sog. „Data-Processing-Agreement“ mit Airbrake abgeschlossen, in dem wir Airbrake verpflichten, die Daten unserer Kunden zu schützen und sie nicht an Dritte weiterzugeben.
                        </p>


                        <h2>  19. Ihre Rechte als betroffene Person </h2>
                        <h3>  19.1 Recht auf Bestätigung </h3>
                        <p>Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden.</p>
                        <h3>  19.2 Recht auf Auskunft Art. 15 DS-GVO </h3>
                        <p>Sie haben das Recht, jederzeit von uns unentgeltliche Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten sowie eine Kopie dieser Daten nach Maßgabe der gesetzlichen Bestimmungen zu erhalten.</p>
                        <h3> 19.3 Recht auf Berichtigung Art. 16 DS-GVO </h3>
                        <p> Sie haben das Recht, die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner steht Ihnen das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung unvollständiger personenbezogener Daten zu verlangen.</p>
                        <h3>19.4 Löschung Art. 17 DS-GVO  </h3>
                        <p> Sie haben das Recht, von uns zu verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich gelöscht werden, sofern einer der gesetzlich vorgesehenen Gründe zutrifft und soweit die Verarbeitung bzw. Speicherung nicht erforderlich ist.</p>
                        <h3> 19.5 Einschränkung der Verarbeitung Art. 18 DS-GVO </h3>
                        <p>Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der gesetzlichen Voraussetzungen gegeben ist.</p>
                        <h3> 19.6 Datenübertragbarkeit Art. 20 DS-GVO </h3>
                        <p>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, welche uns durch Sie bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie haben außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO oder Art. 9 Abs. 2 lit. a) DS-GVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 lit. b) DS-GVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche uns übertragen wurde.<br /> <br />
                            Ferner haben Sie bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.</p>

                        <h3>  19.7 Widerspruch Art. 21 DS-GVO </h3>
                        <p>SIE HABEN DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, JEDERZEIT GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN, DIE AUFGRUND VON ART. 6 ABS. 1 LIT. E) (DATENVERARBEITUNG IM ÖFFENTLICHEN INTERESSE) ODER F (DATENVERARBEITUNG AUF GRUNDLAGE EINER INTERESSENABWÄGUNG) DS-GVO ERFOLGT, WIDERSPRUCH EINZULEGEN. DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING IM SINNE VON ART. 4 NR. 4 DS-GVO.<br /> <br />
                            LEGEN SIE WIDERSPRUCH EIN, WERDEN WIR IHRE PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE BERECHTIGTE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN, ODER SOFERN DIE VERARBEITUNG DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN DIENT.<br /> <br />
                            IN EINZELFÄLLEN VERARBEITEN WIR PERSONENBEZOGENE DATEN, UM DIREKTWERBUNG ZU BETREIBEN. SIE KÖNNEN JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG DER PERSONENBEZOGENEN DATEN ZUM ZWECKE DERARTIGER WERBUNG EINLEGEN. DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WIDERSPRECHEN SIE GEGENÜBER UNS DER VERARBEITUNG FÜR ZWECKE DER DIREKTWERBUNG, SO WERDEN WIR DIE PERSONENBEZOGENEN DATEN NICHT MEHR FÜR DIESE ZWECKE VERARBEITEN.<br /> <br />
                            ZUDEM HABEN SIE DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE SIE BETREFFENDE VERARBEITUNG PERSONENBEZOGENER DATEN, DIE BEI UNS ZU WISSENSCHAFTLICHEN ODER HISTORISCHEN FORSCHUNGSZWECKEN ODER ZU STATISTISCHEN ZWECKEN GEMÄSS ART. 89 ABS. 1 DS-GVO ERFOLGEN, WIDERSPRUCH EINZULEGEN, ES SEI DENN, EINE SOLCHE VERARBEITUNG IST ZUR ERFÜLLUNG EINER IM ÖFFENTLICHEN INTERESSE LIEGENDEN AUFGABE ERFORDERLICH.<br /> <br />
                            IHNEN STEHT ES FREI, IM ZUSAMMENHANG MIT DER NUTZUNG VON DIENSTEN DER INFORMATIONSGESELLSCHAFT, UNGEACHTET DER RICHTLINIE 2002/58/EG, IHR WIDERSPRUCHSRECHT MITTELS AUTOMATISIERTER VERFAHREN AUSZUÜBEN, BEI DENEN TECHNISCHE SPEZIFIKATIONEN VERWENDET WERDEN.</p>
                        <h3>19.8 Widerruf einer datenschutzrechtlichen Einwilligung  </h3>
                        <p> Sie haben das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit mit Wirkung für die Zukunft zu widerrufen.</p>
                        <h3> 19.9 Beschwerde bei einer Aufsichtsbehörde </h3>
                        <p> Sie haben das Recht, sich bei einer für Datenschutz zuständigen Aufsichtsbehörde über unsere Verarbeitung personenbezogener Daten zu beschweren.</p>

                        <h2>  20. Routinemäßige Speicherung, Löschung und Sperrung von personenbezogenen Daten </h2>
                        <p> Wir verarbeiten und speichern Ihre personenbezogenen Daten nur für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern dies durch die Rechtsvorschriften, welchen unser Unternehmen unterliegt, vorgesehen wurde.<br /> <br />
                            Entfällt der Speicherungszweck oder läuft eine vorgeschriebene Speicherfrist ab, werden die personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.</p>


                        <h2>  21. Dauer der Speicherung von personenbezogenen Daten </h2>
                        <p>Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten routinemäßig gelöscht, sofern sie nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind.</p>

                        <h2>  22. Aktualität und Änderung der Datenschutzerklärung </h2>
                        <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand: August 2024.<br /> <br />
                            Durch die Weiterentwicklung unserer Internetseiten und Angebote oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf unserer Webseite von Ihnen abgerufen und ausgedruckt werden.
                        </p>


                    </div>
                </div>
            </div>
        </>
    )
}