import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Me from "../imgs/me.jpg"
import Flower from "../imgs/lebens-blume.png"

export default function AboutMe() {
    return (
        <section style={{ paddingTop: "10px" }} className="section center" id="about me ">
            <img src={Flower} alt="Joanna Cwalina" className="symbol-right" />
            <Container className="content">
                <Row>
                    <Col md={6} className='center order-md-1 order-2 '>
                        <img src={Me} alt="Joanna Cwalina" />
                    </Col>
                    <Col md={6} className='center order-md-2 order-1'>
                        <div>
                            <h2>Über mich</h2>
                            <p>Hi, ich bin Joanna, spirituelle Begleiterin. Ich habe meine eigene Heilung in der Spiritualität gefunden. Nachdem ich selbst schwer erkrankte und mir die konventionelle Medizin keine Lösung bieten konnte, begann ich, alternative Heilmethoden zu erkunden. Unter den vielen verschiedenen Ansätzen fand ich meine tiefe Liebe zur Energiearbeit. Diese transformative Methode hat mein Leben komplett verändert und zu meiner Gesundung geführt.
                                Nach einem Jahr intensiven Lernens und persönlichen Praktizierens haben mich meine spirituellen Führer ermutigt, meine Gabe mit anderen zu teilen. Energiearbeit ist zu meinem Lebensstil geworden, den ich jedem empfehle, der sich in sein Leben verlieben möchte.
                            </p>
                        </div>


                    </Col>

                </Row>
            </Container>
        </section>
    )
}
