import React from 'react'
import { InlineWidget } from 'react-calendly';
import { Container } from 'react-bootstrap';
import Star from "../imgs/star.png"

export default function Calendly() {
    return (
        <section style={{ padding: "20px 0" }} className="section" id="calender">

            <img src={Star} alt="star" style={{ height: "200px" }} className="symbol" />

            <Container className="content" >

                <div className="calendly-container">
                    <InlineWidget url="https://calendly.com/joannacwalina/kennenlerngespraech" />
                </div>

            </Container>

            {/* <img src={Star} alt="Joanna Cwalina" style={{ bottom: "50px" }} className="symbol" /> */}
        </section>
    );
}

