import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Hand from "../imgs/handStones.png"

export default function MoreServices() {
    return (
        <section className="section center more-services" id="moreServices">
            <Container className="content  ">
                <Row>
                    <Col md={6} >
                        <div className="content">
                            <h2>Weitere Angebote von mir:</h2>
                            <ul>
                                <li>Fernbehandlung</li>
                                <li>Innere Kind Arbeit</li>
                                <li>Das Zurückholen von Seelenanteilen</li>
                                <li>Das Clearing von Menschen, Sachen und Räumen</li>
                                <li>Die Öffnung des 3. Auges</li>
                                <li>Schutz und Segen</li>
                                <li>Beruhigung auf allen Ebenen</li>
                                <li>Heilreise in Gruppen</li>
                            </ul>
                            <div className="pt-3">
                                <p><strong> Coaching (Innere Kind Arbeit):</strong> 55 € / 1 Stunde</p>
                                <p style={{ marginBottom: "0" }}><strong>Ein Clearing über die Ferne:</strong> 35 €</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} className=" center">
                        <img src={Hand} alt="Chakra Healing" className="img-fluid" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
