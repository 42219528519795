import "../styles/header.scss"
import Logo from "../imgs/logo.png"
import { Button } from "../UI/Buttons";
import { Link } from 'react-router-dom';

export const Header = () => {
    return (
        <div className="header">
            <Link to="/">
                <img src={Logo} alt="Logo" className="header-logo" />
            </Link>

            <div className="header-content">
                <h1 className="header-title">Joanna Cwalina</h1>
                <h2 className="header-subtitle">
                    Beginne deine Heilreise und lass dich führen
                </h2>
                <Button
                    link={"https://calendly.com/joannacwalina/kennenlerngespraech"}
                    text="Buche dein kostenloses Kennenlerngespräch"
                />
            </div>
        </div>
    )
}