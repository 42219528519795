import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from "../UI/Buttons";
import '../styles/main.scss';
import Flower from "../imgs/lebens-blume.png"
import ProfileImg from "../imgs/light.jpg"

export default function HealingSession() {
    return (
        <section className="section center" id="healing-session">
            <img src={Flower} alt="Decorative Symbol" className="symbol-left" />
            <Container className="content">
                <Row>
                    <Col md={6} >

                        <h2>1:1 Heilreise</h2>
                        <p >In meiner 1:1 Begleitung,  lade ich dich ein, Energiebehandlungen und Coaching, sowie Innere Kind Arbeit, zu erleben. Jede Sitzung ist individuell und es kann intuitiv entschieden werden, ob Coaching, Energiearbeit oder beide im Vordergrund stehen sollen. Auf unserer gemeinsamen Heilreise kannst du Licht, Liebe und göttliche Energie in Fülle spüren.
                            Ich richte mich nach den aktuellen Bedürfnissen deines Körpers und channele genau das, was in diesem Moment am meisten gebraucht wird, um energetische Blockaden zu lösen und tiefgehende Heilung zu fördern.
                            <br /> <br />
                            <b>Energieausgleich:</b>    75 € / 1,5 Stunden<br />
                            <b>Orte:</b> Online / Schifferstadt / Karlsruhe<p>
                                (ab 1.10. auch in Mannheim und Heidelberg )
                            </p>

                        </p>

                        <Button
                            link={"https://calendly.com/joannacwalina/1-1-heilreise"}
                            text="Buche deine Heilreise"
                        />
                    </Col>
                    <Col md={6} className='center'>
                        <img src={ProfileImg} alt="Joanna Cwalina" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
};

