import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/main.scss';
import Flower from "../imgs/lebens-blume.png"
import Women from "../imgs/frau.jpg"

export default function Services() {
    return (
        <section className="section" id="services">
            <img src={Flower} alt="Decorative Symbol" className="symbol" />
            <Container className="content">
                <Row>
                    <Col md={6} className='center'>
                        <h2 style={{ textAlign: "center" }}>Du bist hier richtig, wenn du...</h2>
                        <ul>
                            <li>emotionale Schmerzen heilen willst.</li>
                            <li>Unterstützung bei der Bewältigung von Krankheiten brauchst.</li>
                            <li>körperliche und mentale Blockaden lösen möchtest.</li>
                            <li>bereit bist dich von der Vergangenheit zu lösen.</li>
                            <li>endlich in deine Kraft kommen willst.</li>
                            <li>deine Selbstheilungskräfte aktivieren möchtest.</li>
                            <li>eine tiefere Verbindung zu dir selbst suchst.</li>
                            <li>glücklicher und leichter durchs Leben gehen willst.</li>
                        </ul>
                        <h2
                            className='h2-2'

                        >...das alles kannst du mit einer Energiebehandlung in die Heilung bringen</h2>
                    </Col>
                    <Col md={6} className='center'>
                        <img src={Women} alt="Healing" />
                    </Col>
                </Row>
            </Container>
        </section>
    )
};

