import React from 'react';
import { Container } from 'react-bootstrap';
import '../styles/main.scss';


export default function EnergySession() {
    return (
        <section className="section-energy ">
            <Container className="content center">
                <h2>Was ist eine Energiebehandlung?</h2>
                <p style={{ textAlign: "center", maxWidth: "500px", marginTop: "30px" }}>
                    Eine Energiebehandlung ist eine ganzheitliche Praxis, die den Energiefluss in deinem Körper durch
                    heilsame Frequenzen harmonisiert und stärkt. Mit sanfter Berührung oder sogar ohne direkten Kontakt
                    werden energetische Blockaden identifiziert und gelöst. Diese liebevolle Methode unterstützt dein
                    körperliches, emotionales, mentales und spirituelles Wohlbefinden, indem sie deine natürlichen
                    Selbstheilungskräfte aktiviert und dein Energiesystem ins Gleichgewicht bringt.
                </p>
            </Container>
        </section>
    );
};
