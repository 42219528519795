import React from 'react'
import { Container } from 'react-bootstrap';


export default function Quote() {
    return (
        <section className="section-quote" style={{ height: "50vh" }}>
            <Container className="content center h-100">
                <h2 style={{ color: "#ffffffa1", fontStyle: "italic" }}>“Öffne dich für die Wunder des Lebens und entdecke die transformative Kraft der Energiearbeit.”</h2>
            </Container>
        </section>
    )
}
